import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';
import { Section } from '../components/section';

import * as styles from './index.module.css';

const Charte = ({ data }) => {
  return (
    <div className={styles.main}>
      <div>
        <Helmet>
          <meta name="description" content="Charte aude-bien-etre" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>

      <div className={styles.mainContent}>
        <Section title="Charte">
          {/* <h1>Charte</h1> */}
          <div className={styles.text}>
            <p>
              Assurer, conformément à la loi du 30 avril 1946, au décret n° 60665 du 4 juillet 1960, à l'article L 489
              du Code de Santé Publique et au décret n° 96-879 du 8 octobre 1996, qu’il ne s'agit nullement de massages
              à but médical, thérapeutique ou de kinésithérapie mais de techniques de Bien-Être.
            </p>
            <p> Réaliser des massages Bien-Être non thérapeutiques et non sexuels.</p>
            <p> Respecter les règles liées au secret professionnel et à la discrétion professionnelle.</p>
            <p> Respecter l'intégrité physique et morale du client.</p>
            <p> Respecter l'intimité et la pudeur du client.</p>
            <p>
              {' '}
              Respecter les règles d'hygiène liées à l'exercice de ce métier et attendre ce même respect de la part du
              public accueilli.
            </p>
          </div>
        </Section>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Charte;
