import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import { Header } from '../components/header';
import { Section } from '../components/section';

import * as mainStyle from './index.module.css';
import * as stylesLayout from './index.module.css';
import * as styleAvis from './avis.module.css';
import facebookImage from '../images/icon-facebook.png';
import googleLogo from '../images/Google_Logo.svg';

const Avis = ({ data }) => {
  return (
    <div className={mainStyle.main}>
      <div>
        <Helmet>
          <meta name="description" content="Les avis des clients sur google et facebook" />
          <title>{data.site.siteMetadata.title}</title>
        </Helmet>
        <Header></Header>
      </div>

      <div className={stylesLayout.mainContent}>
        <Section title="Avis">
          <p>
            Vous pouvez retrouver tous les avis déposés par mes clients sur{' '}
            <a
              href="https://www.google.com/maps/place/Aude+Bien-Etre,+Massage+et+relaxation/@47.2775488,-1.5132479,17z/data=!4m14!1m6!3m5!1s0x4805efd0d289e531:0x5ffe4141effa397d!2sAude+Bien-Etre,+Massage+et+relaxation!8m2!3d47.2775443!4d-1.5132448!3m6!1s0x4805efd0d289e531:0x5ffe4141effa397d!8m2!3d47.2775443!4d-1.5132448!9m1!1b1"
              target="_blank"
              rel="noreferrer">
              <img width="50px" src={googleLogo} alt="icône Google" />
              <strong>Google</strong>
            </a>{' '}
            ou sur{' '}
            <a href="https://www.facebook.com/aude.bien.etre44/" target="_blank" rel="noreferrer">
              <img width="50px" src={facebookImage} alt="icône Facebook" />
              <strong>Facebook</strong>
            </a>
          </p>
          <div className={styleAvis.avis}>
            <p className={styleAvis.author}>Céline</p>
            <p className={styleAvis.text}>
              Merci Aude pour ton accueil et ton professionnalisme. J’ai beaucoup apprécié ce moment de détente rien que
              pour moi. Un massage doux, un pur moment de douceur et de lâcher prise.
            </p>
          </div>
          <div className={styleAvis.avis}>
            <p className={styleAvis.author}>Hugo</p>
            <p className={styleAvis.text}>
              Aude est très professionnelle. Première approche découverte agréable pour instaurer un climat de confiance
              . Elle s’adapte en fonction de nos attentes. Je recommande fortement le massage d’une heure . Ps: si c’est
              le mois de ton anniversaire tu as une petite réduction 😉
            </p>
          </div>
          <div className={styleAvis.avis}>
            <p className={styleAvis.author}>Ingrid</p>
            <p className={styleAvis.text}>
              Un pur moment de bien être et de détente. Aude est très attentive à l'état global de la personne et prends
              le temps d écouter les attentes du patient. Ces massages sont très professionnels. Je vous la recommande à
              100%
            </p>
          </div>
          <div className={styleAvis.avis}>
            <p className={styleAvis.author}>Marc</p>
            <p className={styleAvis.text}>
              Ma femme a participé à l'atelier expression corporelle et relaxation. Voici son avis : "Que du bonheur !
              Petit groupe très convivial où chacune a pu s'exprimer sans gêne et avec beaucoup d'énergie. Sur des
              musiques diverses et variées avec Aude comme guide, nous avons pu évoluer, bouger, danser en occupant tout
              l'espace. La mise en confiance d'Aude nous a permis de libérer nos tensions et de positiver sur la manière
              de nous exprimer. Le retour au calme a été un moment d'évasion et de relâchement. Très belle découverte.
              J'attends avec impatience le prochain atelier.
            </p>
          </div>
          <div className={styleAvis.avis}>
            <p className={styleAvis.author}>Kate-Aline</p>
            <p className={styleAvis.text}>
              Aude intervient tous les mois auprès de notre entreprise sur le temps de notre pause déjeuner. C'est une
              personne très agréable, très professionnelle qui ravie tous les employés qui passent entre ses mains.
              Grâce à elle, nous sommes détendus après notre séance de massage thaï assis. Je recommande Aude Bien-être
              au sein des entreprises qui souhaitent prendre soin de leurs employés pour une meilleure productivité.
            </p>
          </div>
          <div className={styleAvis.avis}>
            <p className={styleAvis.author}>Estelle</p>
            <p className={styleAvis.text}>
              J'y suis allée car j'étais enceinte, fatiguée et douloureuse et j'en suis ressortie apaisée et soulagée.
              Avec Aude, on a la garantie d'être chouchoutée et écoutée. Elle prend le temps de nous connaître et de
              nous mettre en confiance. C'est une professionnelle du massage qui propose de vrai massages complets avec
              diverses méthodes et le temps donné correspond (1h = 1h de massage et non 15mn de déshabillage et
              rhabillage en moins comme dans les instituts classiques). Le lieu est bien aménagé, intimiste et calme,
              les prix plus que raisonnables. Je la recommande ;)
            </p>
          </div>
        </Section>
      </div>
    </div>
  );
};

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
export default Avis;
